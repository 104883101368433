import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AccountDetailsForm as GenericAccountDetailsForm } from 'components/generic';
import { useAccountSetupEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useAddShippingInfoEventGA4, useCheckoutSignInEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import {
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  GTM_ANALYTICS_CONSTANTS,
  STEP_PATH,
} from '../../constants';
import config from '../../Config';
import '../../app/App.scss';

export const NewTrialAccountDetailsForm: React.FC<{
  submitButtonText: string
  isNewUser: boolean
  emailValue: string
}> = ({
  submitButtonText, isNewUser, emailValue
}) => {
  const {
    region
  } = useSessionContext();
  const { search } = useLocation();

  const [isTermsCheck, setIsTermsCheck] = React.useState(false);
  const [termsErrorMessage, setTermsErrorMessage] = React.useState('');
  const [sendPromotionsCheck, setSendPromotionsCheck] = React.useState(true);
  const [disabled, setDisableValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const setTerms = () => {
    setIsTermsCheck(!isTermsCheck);
    setTermsErrorMessage('');
  };

  const setSendPromotions = () => {
    setSendPromotionsCheck(!sendPromotionsCheck);
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched' });

  const { loginAfterLogout } = useAuth0();

  const appState = () => ({ clientInformation: { ...getValues(), sendPromotions: sendPromotionsCheck } });

  const postDirectDebitEvent = () => {
    if (!isTermsCheck) {
      setTermsErrorMessage("Please confirm you've acknowledged the terms of use");
    } else {
      handleSubmit(() => onSubmit())();
    }
  };
  const {
    businessName, phoneNumber, lastName, firstName
  } = getValues();
  const identifyParamsOld = {
    last_name: lastName,
    first_name: firstName,
    business_name: businessName,
    phone_number: phoneNumber,
    terms_and_conditions: isTermsCheck,
    communication_preference: sendPromotionsCheck
  };

  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);

  const identifyParams = {
    userId,
    anonymousId,
    traits: {
      ...identifyParamsOld,
      myobVisitorId
    }
  };

  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT,
    userId,
    anonymousId,
    properties: {
      myobVisitorId
    }
  };

  useAccountSetupEvent();
  useAccountSetupEvent(identifyParamsOld, isSubmit, GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT);
  useAddShippingInfoEventGA4(isNewUser);
  useCheckoutSignInEventGA4(isNewUser, isSubmit);
  useSendEventToSegment({
    shouldSendSegmentEvent: isSubmit,
    identifyParams,
    trackParams
  });
  const onSubmit = async () => {
    setIsSubmit(true);
    setDisableValue(true);
    const redirectUrl = `${config.BUY_BASE_URL}/${region.toLowerCase()}${STEP_PATH.NEW_TRIAL.CONFIRMATION}${search}`;
    if (isNewUser) {
      const email = getValues('email');
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          screen_hint: 'signup',
          ui_hint: 'essentials',
          login_hint: email,
          redirect_uri: redirectUrl
        }
      });
    } else {
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          login_hint: emailValue,
          redirect_uri: redirectUrl
        }
      });
    }
  };
  return (
    <GenericAccountDetailsForm
      emailValue={emailValue}
      disabled={disabled}
      handleSubmit={postDirectDebitEvent}
      // todo: get rid of submitButtonText prop
      submitButtonText={submitButtonText}
      isTermsCheck={isTermsCheck}
      termsErrorMessage={termsErrorMessage}
      setTerms={setTerms}
      sendPromotionsCheck={sendPromotionsCheck}
      setSendPromotions={setSendPromotions}
      register={register}
      errors={errors}
    />
  );
};
