import React, { ReactNode } from 'react';
import { Main as GenericMain } from 'components/main/Main';
import { ContentPanel } from 'components/contentPanel/ContentPanel';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import FeatureList from 'components/FeatureList/FeatureList';
import { NewFeatureContainer } from 'components/newFeatureContainer/NewFeatureContainer';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { SUBSCRIPTION_TYPE } from '../../constants';
import './Main.scss';

const TrialMain = ({ children }: { children?: ReactNode }) => (
  <div>
    <div data-testid='top-bar' className='trial-top-bar' style={{ height: '10px' }}/>
    <div data-testid='main-content' className='trial-main-content'>
      <NewFeatureContainer>
        <FeatureList />
      </NewFeatureContainer>
      <ContentPanel faded={false}>
        {children}
        <RecaptchaDescription/>
      </ContentPanel>
    </div>
  </div>
);

export const Main: React.FC<{
  children?: ReactNode,
}> = ({ children }) => {
  const { subscriptionType } = useSessionContext();
  if (subscriptionType === SUBSCRIPTION_TYPE.TRIAL) {
    return <TrialMain>{children}</TrialMain>;
  }
  return <GenericMain>{children}</GenericMain>;
};
