import React from 'react';
import { Box, Pill, TagIcon } from '@myob/myob-widgets';
import { get } from 'lodash';
import { useProductData } from 'hooks/useProductData/useProductData';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { PricingMolecule } from '../priceMolecule/PricingMolecule';
import soloStyles from './PricingCard.solo.module.scss';
import defaultStyles from './PricingCard.module.scss';
import { FEELIX_THEMES } from '../../../constants';
import { ModulePrice } from '../modulePrice/ModulePrice';

type PriceProps = {
  price: string;
  currency: string;
  isExcludeTax: boolean;
}
type PricingCardProps = {
  pricing: PriceProps,
  theme: string
}

const getPriceDenominator = (paymentSchedule: string, isExcludeTax: boolean) => {
  const taxDescription = isExcludeTax ? '(+ GST)' : '(inc. GST)';
  return `/${paymentSchedule} ${taxDescription}`;
};

const getStyle = (theme: string) => {
  if (theme === FEELIX_THEMES.SOLO) {
    return soloStyles;
  }
  return defaultStyles;
};
export const PricingCard: React.FC<PricingCardProps> = ({
  pricing: {
    price, isExcludeTax, currency
  }, theme
}) => {
  const style = getStyle(theme);
  const { isAnnualPrice } = useSessionContext();
  const paymentSchedule = isAnnualPrice ? 'year' : 'month';
  const { productData, moduleProductData } = useProductData();
  const productItems = get(productData, 'items');
  const promotionName = get(productItems[0], 'deal.promotionName');

  return (
    <Box className={style.pricingCard}>
      {promotionName && (<Pill label={promotionName}
        prefixAccessory={<TagIcon color={'inherit'} />} className={style.offerPill}
      />)}
      <PricingMolecule
        price={price}
        denominator={getPriceDenominator(paymentSchedule, isExcludeTax)}
        currency={currency}
        theme={theme}
      />
      <ModulePrice
        productData={productData}
        moduleProductData={moduleProductData}
      />
    </Box>
  );
};
