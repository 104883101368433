import axios from 'helpers/axios/axios';
import config from 'Config';
import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

type ValidateCreditCardType = {
  isLoading: boolean;
  isValid: boolean;
  isError: boolean;
  refetch: () => Promise<QueryObserverResult<AxiosResponse>>;
}

export const useCreditCardValidator = (firstSixDigits: string, customer: string | undefined)
: ValidateCreditCardType => {
  const {
    isLoading, data, isError, refetch
  } = useQuery(
    ['firstSixDigits', firstSixDigits, 'customer', customer],
    () =>
      axios.get(config.API_CREDIT_CARD_VALIDATION_ENDPOINT, { params: { firstSixDigits, customer } }),
    { enabled: false, retry: false, staleTime: 1000 * 60 * 5 }
  );

  const isValid = data?.data.validFirstSixDigits;
  return {
    isLoading, isError, isValid, refetch
  };
};
