import axios from 'helpers/axios/axios';
import config from 'Config';
import { useQuery } from 'react-query';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../Auth0/Auth0';
import { OrderItem, ProductData } from './types';
import { PRODUCT_MAPPING } from '../../constants';

type UseGetProductType = {
  productData: ProductData,
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

export const useGetModuleProduct = (isGetBaseProductSuccess: boolean, items: OrderItem[]): UseGetProductType => {
  const history = useHistory();
  const { isLoading: isAuth0Loading } = useAuth0();
  const { isInitialised, productId } = useSessionContext();
  const moduleProductId = (PRODUCT_MAPPING as any)[parseInt(productId, 10)];
  const presentmentUrl = `${config.API_PRODUCT_PRICE_ENDPOINT}?productId=${moduleProductId}`;

  const {
    isLoading: isProductDataLoading, isError, data, isSuccess
  } = useQuery(
    ['productData', moduleProductId],
    () => axios.get(presentmentUrl),
    {
      enabled:
        !isAuth0Loading &&
        isInitialised &&
        isGetBaseProductSuccess &&
        (items[0].productCode === 'SB' || items[0].productCode === 'ACCOUNTRIGHT') &&
        moduleProductId !== undefined,
      retry: false,
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  );

  if (isError) {
    history.push('/error');
  }
  const isLoading = !isInitialised || isProductDataLoading || isAuth0Loading;

  const productData = data?.data || {} as ProductData;
  return {
    productData, isLoading, isError, isSuccess
  };
};
