import React from 'react';
import {
  Box, Heading, Spinner
} from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useConfirmationContent } from 'hooks/useConfirmationContent/useConfirmationContent';
import style from './DefaultConfirmation.module.scss';
import { ConfirmationProps } from '../types';
import { SUBSCRIPTION_TYPE } from '../../../../constants';
import { ConfirmationButtons } from './ConfirmationButtons';
import { TrialContent } from './TrialContent';
import { SubscribeContent } from './SubscribeContent';

export const DefaultConfirmation: React.FC<ConfirmationProps> =
  ({
    isLoading
  }) => {
    const {
      subscriptionType
    } = useSessionContext();
    const confirmationContent = useConfirmationContent();
    return (
      <Box className={style.container}>
        <Box className={style.content}>
          {isLoading && <Spinner size='small' data-testid='spinner'/>}
          {!isLoading && (
          <React.Fragment>
            <Heading level={1} className={style.heading}>
              {confirmationContent.title}
            </Heading>
            { subscriptionType === SUBSCRIPTION_TYPE.NEW_TRIAL && <TrialContent/> }
            { subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE && <SubscribeContent {...confirmationContent} />}
            <ConfirmationButtons { ...confirmationContent} />
          </React.Fragment>
        )}
        </Box>
      </Box>
    );
  };
