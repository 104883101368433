import React from 'react';
import { Button } from '@myob/myob-widgets';
import ProgressButton from 'components/confirmation/progressButton/ProgressButton';
import { ConfirmationButtonsType } from 'hooks/useConfirmationContent/types';
import { ANALYTICS_ID } from '../../../../constants';
import config from '../../../../Config';

export const ConfirmationButtons: React.FC<ConfirmationButtonsType> =
  ({
    button
  }) => {
    if (button === 'progress') {
      return (
        <ProgressButton
          id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
          startText='Setting up your software'
          endText='Access your software now'
          duration={config.DEEP_LINKING.BUTTON_DISABLE_TIME}
          href={config.PRODUCT_URL}
        />
      );
    }

    return (
      <a
        style={{ textDecoration: 'none' }}
        href={button.link}
        target='_blank'
        rel='noreferrer'
      >
        <Button>
          {button.text}
        </Button>
      </a>
    );
  };
