import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { EmailForm } from 'components/generic';
import { useBeginCheckoutEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useBeginCheckoutEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useCheckoutStarted } from 'hooks/Analytics/useAnalytics';
import { useSegmentContext } from 'contexts/segmentProvider/SegmentProvider';
import {
  NINETAILED_ANALYTICS_CONSTANTS,
  REGULAR_EXPRESSIONS
} from '../../constants';

export const EmailInputForm: React.FC<{
  handleEmailChecked: (emailValue: string) => void
  isError: boolean
}> = ({
  handleEmailChecked, isError
}) => {
  const isSegmentLibraryReady = useSegmentContext();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched' });

  const [disabled, setDisableValue] = useState(false);
  const emailRegister = register('email', { required: true, pattern: REGULAR_EXPRESSIONS.EMAIL });

  useBeginCheckoutEvent();
  useCheckoutStarted(isSegmentLibraryReady);
  useBeginCheckoutEventGA4(isSegmentLibraryReady);
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT);

  const onSubmit = handleSubmit((data) => {
    setDisableValue(true);
    handleEmailChecked(data.email);
  });

  return (
    <EmailForm
      handleSubmit={onSubmit}
      submitDisabled={disabled}
      formRegister={emailRegister}
      isError={isError}
      errors={errors}
    />
  );
};
