import React, { ReactNode, FC, ComponentType } from 'react';

export type ComponentWithProps = ComponentType<any> | [ComponentType<any>, Record<string, any>];

export const combineComponents = (...componentsWithProps: ComponentWithProps[]): FC<{ children?: ReactNode }> => {
  const components = componentsWithProps.map((component) => {
    const [Component, props] = Array.isArray(component) ? component : [component, {}];
    return ({ children }: { children?: ReactNode }) => (
      <Component {...props}>{children}</Component>
    );
  });

  return components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }: { children?: ReactNode }): JSX.Element => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
    ({ children }: { children?: ReactNode }) => <>{children}</>
  );
};
