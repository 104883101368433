import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from '@myob/myob-widgets';
import { useAuth0 } from 'hooks/Auth0/Auth0';

import { useSendPurchaseEventAndOrderCompletedEvent, useContentViewed } from 'hooks/Analytics/useAnalytics';
import { get } from 'lodash';
import { useTrialOrderingCall } from 'hooks/useTrialOrderingCall/useTrialOrderingCall';
import { useEnhancedPurchaseEvent, usePurchaseEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { usePurchaseEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import ProgressButton from 'components/confirmation/progressButton/ProgressButton';
import laptopImage from 'assets/images/computer-screen.png';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import { ANALYTICS_ID, GTM_ANALYTICS_CONSTANTS, NINETAILED_ANALYTICS_CONSTANTS } from '../../../constants';
import config from '../../../Config';
import '../Confirmation.scss';

function useTrackTrialConfirmationPage(email: string | undefined) {
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_TRIAL_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  // Ninetailed track event for checkout with payment
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);
}

export const TrialConfirmation: React.FC = () => {
  const { user, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const { isLoading: isOrderingLoading, isError } = useTrialOrderingCall();
  const email = get(user, 'email');

  useTrackTrialConfirmationPage(email);

  const isLoading = isAuth0Loading || isOrderingLoading;
  if (!isLoading && (!isAuthenticated || isError)) {
    return <Redirect to={'/error'}/>;
  }
  if (isLoading) {
    return <Spinner size='small' data-testid='spinner'/>;
  }

  return (
    <div className='confirmation-page-container'>
      <h1 className='confirmation-page-title'>What happens next?</h1>
      <div className='confirmation-page-main'>
        <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
        <div className='confirmation-page-main-image-desc'>
          <p><b>Access your product</b></p>
          <p>Learn the ropes with the help of your own personalised setup activities</p>
        </div>
      </div>
      <ProgressButton
        id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
        startText='Setting up your software'
        endText='Access your software now'
        duration={config.DEEP_LINKING.BUTTON_DISABLE_TIME}
        href={config.PRODUCT_URL}
      />
    </div>
  );
};
