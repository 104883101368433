import React from 'react';
import { Box } from '@myob/myob-widgets';
import { TermsConditions } from 'components/terms/Terms';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ProductData } from 'hooks/useGetProduct/types';
import { useProductData } from 'hooks/useProductData/useProductData';
import { getTagPrice } from 'utils/pricingUtils';
import isNZProduct from 'utils/IsNZProduct';
import { PricingCard } from '../pricingCard/PricingCard';
import style from './ProductFooter.module.scss';
import { FeatureList } from '../featureList/FeatureList';
import { getStyleProps } from './ProductFooter.style';

interface ProductHeadingProps {
  theme: string;
}

const getPricing = (productData: ProductData, isAnnualRoute: boolean, promoCode: string | undefined) =>
  ({
    price: getTagPrice(productData?.items, isAnnualRoute, promoCode, productData?.region),
    currency: productData.currency || 'AUD',
    isExcludeTax: isNZProduct(productData?.items[0]?.productId)
    // when annual is moved to nbs this will be dynamic
  });
export const ProductFooter: React.FC<ProductHeadingProps> =
  ({
    theme
  }) => {
    const {
      productData,
      baseFeatureList,
      baseAndModuleFeatureList,
      productId,
      showModulePrice,
      promoCode,
      availableTo
    } = useProductData();
    const styleProps = getStyleProps(theme);
    const { isAnnualRoute } = useSessionContext();
    return (
      <Box
        className={style.productFooter}
        {...styleProps.footer}
      >
        <PricingCard
          pricing={getPricing(productData, isAnnualRoute, promoCode)}
          theme={theme}
        />
        <FeatureList
          items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList}
          theme={theme}
        />
        { promoCode && availableTo && <TermsConditions className={style.termsAndConditions}/>}
      </Box>
    );
  };
