import React,
{
  ReactElement,
  ReactNode,
  useRef,
  useCallback
} from 'react';
import { trackClickEvent } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useClickHandler } from 'hooks/useClickHandler/useClickHandler';
import style from './Accordion.module.scss';
import { GTM4_ANALYTICS_CONSTANTS } from '../../constants';

interface AccordionProps {
  title: string;
  closeOnOutsideClick?: boolean;
  trackEvents?: boolean;
  header: ReactElement;
  isOpen: boolean;
  onClick: (e: any, open: boolean) => void;
  className?: any;
  animationDuration?: number;
  containerClassName?: string;
  children?: ReactNode;
}
export const Accordion: React.FC<AccordionProps> =
  ({
    title,
    isOpen,
    onClick,
    header,
    children,
    className,
    animationDuration = 0.5,
    closeOnOutsideClick = true,
    containerClassName = '',
    trackEvents = true
  }) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const handleClick = useCallback((e: any) => {
      if (trackEvents) {
        trackClickEvent(
          isOpen ? GTM4_ANALYTICS_CONSTANTS.ACCORDION_CLOSE : GTM4_ANALYTICS_CONSTANTS.ACCORDION_OPEN,
          { content_title: title }
        );
      }
      onClick(e, !isOpen);
    }, [isOpen, onClick, title, trackEvents]);

    const ref = useClickHandler((e, clickedOutside) => {
      if (closeOnOutsideClick && isOpen && clickedOutside) {
        handleClick(e);
      }
    });

    return (
      <div className={`${style.container} ${containerClassName}`} ref={ref}>
        <button onClick={handleClick}>
          {header}
        </button>
        <div
          ref={contentRef}
          style={{
            overflow: isOpen ? 'auto' : 'hidden',
            transition: `height ${animationDuration}s ease`,
            height: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px'
          }}
          className={className}
        >
          {children}
        </div>
      </div>
    );
  };
