import React, { ReactNode } from 'react';
import { ThemeProvider as FeelixThemeProvider } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';

interface ThemeProviderProps {
  children?: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { theme } = useSessionContext();
  return (
    <FeelixThemeProvider theme={theme}>
      {children}
    </FeelixThemeProvider>
  );
};
