import React from 'react';
import { Box } from '@myob/myob-widgets';
import laptopImage from 'assets/images/computer-screen.png';
import style from './DefaultConfirmation.module.scss';

export const TrialContent = () => (
  <Box className={style.trialContent}>
    <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
    <div className='confirmation-page-main-image-desc'>
      <p><b>Access your product</b></p>
      <p>Learn the ropes with the help of your own personalised setup activities</p>
    </div>
  </Box>
);
