import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import config from 'Config';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { useEffect } from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { REQUEST_HEADERS } from '../../constants';
import { useContact } from './useContact';
import { ContactDetails, ContactResult } from './types';

export const useContactCreation = (): ContactResult => {
  const { isLoading: isAuth0Loading, appState, user } = useAuth0();
  const myobIdentityId = user?.['https://id.myob.com/user_id']?.toLowerCase();
  const { accessToken } = useGetAccessToken();
  const contact = useContact();

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isContactsLoading,
    data: contactData,
    isError,
    isSuccess
  } = useQuery(
    'contacts',
    () =>
      axios.post<ContactDetails>(
        config.API_CONTACTS_ENDPOINT,
        qs.stringify(contact),
        options
      ),
    { enabled: !!appState && !!myobIdentityId && !!accessToken, retry: false }
  );

  const { setContactArchieRowId } = useSessionContext();
  useEffect(() => {
    if (isSuccess) {
      setContactArchieRowId(contactData?.data.id as string);
    }
  }, [isSuccess]);

  const contactDetails = contactData?.data;
  const isLoading = isAuth0Loading || isContactsLoading;
  return {
    isLoading, contactDetails, isError
  };
};
