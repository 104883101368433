import React, { ReactNode } from 'react';
import './NewFeatureContainer.scss';

interface NewFeatureContainerProps {
  children?: ReactNode;
}

export const NewFeatureContainer: React.FC<NewFeatureContainerProps> = ({
  children
}) => (
  <>
    <div
      data-testid='trial-feature-box'
      className='trial-feature-box'
    >
      {children}
    </div>
  </>
);
