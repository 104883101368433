import { get, pick } from 'lodash';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { camelToSnake } from 'helpers/camelToSnake/camelToSnake';
import { ContactDetails } from './types';

export const useContact = (): ContactDetails => {
  const { appState, user } = useAuth0();
  const myobIdentityId = user?.['https://id.myob.com/user_id']?.toLowerCase();
  const myobIdEmail = user?.email;

  return camelToSnake({
    ...pick(get(
      appState,
      'clientInformation'
    ), 'firstName', 'lastName', 'phoneNumber', 'sendPromotions'),
    email: myobIdEmail,
    identityId: myobIdentityId
  }) as ContactDetails;
};
