import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Input, Box } from '@myob/myob-widgets';
import { Button } from 'components/button/Button';
import { SubscriptionHeading } from 'components/subscriptionHeading/SubscriptionHeading';
import { trimOnBlur } from 'helpers/trimOnBlur/trimOnBlur';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import TermsOfUse from 'components/termsOfUse/TermsOfUse';
import {
  ANALYTICS_ID,
  FEELIX_THEMES,
  REGULAR_EXPRESSIONS
} from '../../../constants';
import style from './AccountDetailsForm.module.scss';

export const AccountDetailsForm: React.FC<{
  emailValue: string,
  disabled: boolean,
  handleSubmit: () => void,
  submitButtonText: string,
  isTermsCheck: boolean,
  termsErrorMessage: string,
  setTerms: () => void,
  sendPromotionsCheck: boolean,
  setSendPromotions: () => void,
  register: any,
  errors: any
}
> = ({
  emailValue, disabled, handleSubmit, submitButtonText, isTermsCheck,
  termsErrorMessage, setTerms, sendPromotionsCheck, setSendPromotions, register, errors
}) => {
  const { theme } = useSessionContext();
  const { getLabels } = useContentContext();

  const givenNameLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_GIVEN_NAME);
  const familyNameLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_FAMILY_NAME);
  const phoneLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_PHONE);

  const emailRegister = register('email', { required: true, pattern: REGULAR_EXPRESSIONS.EMAIL });
  const firstNameRegister = register('firstName', { required: true, pattern: REGULAR_EXPRESSIONS.FIRST_NAME });
  const lastNameRegister = register('lastName', { required: true, pattern: REGULAR_EXPRESSIONS.LAST_NAME });
  const phoneNumberRegister = register('phoneNumber', { required: true, pattern: REGULAR_EXPRESSIONS.PHONE });
  const businessNameRegister = register('businessName', {
    required: true,
    validate: (value: string) =>
      REGULAR_EXPRESSIONS.BUSINESS_NAME.test(value) && REGULAR_EXPRESSIONS.NO_ONLY_SPACE.test(value)
  });

  const handleBlur = (register: UseFormRegisterReturn) => (event: FocusEvent) => trimOnBlur(event, register);

  return (
    <Box className={[
        {
          [style.soloContainer]: theme === FEELIX_THEMES.SOLO,
          [style.defaultContainer]: theme !== FEELIX_THEMES.SOLO
        }
    ]}
      data-testid='account-details-form'
    >
      <SubscriptionHeading theme={theme}/>
      <Input
        id={ANALYTICS_ID.INPUT_EMAIL}
        label='Email*'
        {...{ ...emailRegister, reference: emailRegister.ref }}
        value={emailValue}
        disabled
      />
      <Input
        id={ANALYTICS_ID.INPUT_FIRST_NAME}
        label={`${givenNameLabel}*`}
        {...{ ...firstNameRegister, reference: firstNameRegister.ref }}
        onBlur={handleBlur(firstNameRegister)}
        errorMessage={
          errors.firstName && (errors.firstName.type === 'required' ?
          `${givenNameLabel} is required` : `${givenNameLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_LAST_NAME}
        label={`${familyNameLabel}*`}
        {...{ ...lastNameRegister, reference: lastNameRegister.ref }}
        onBlur={handleBlur(lastNameRegister)}
        errorMessage={
          errors.lastName && (errors.lastName.type === 'required' ?
          `${familyNameLabel} is required` : `${familyNameLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_PHONE_NUMBER}
        label={`${phoneLabel}*`}
        {...{ ...phoneNumberRegister, reference: phoneNumberRegister.ref }}
        onBlur={handleBlur(phoneNumberRegister)}
        inputMode='tel'
        errorMessage={
          errors.phoneNumber && (errors.phoneNumber.type === 'required' ?
          `${phoneLabel} is required` : `${phoneLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_BUSINESS_NAME}
        label='Business name*'
        {...{ ...businessNameRegister, reference: businessNameRegister.ref }}
        onBlur={handleBlur(businessNameRegister)}
        errorMessage={errors.businessName && (errors.businessName.type === 'required' ?
          'Business name is required' : 'Business name is invalid')}
      />
      <TermsOfUse
        isTermsCheck={isTermsCheck} termsErrorMessage={termsErrorMessage} setTerms={setTerms}
        sendPromotionsCheck={sendPromotionsCheck} setSendPromotions={setSendPromotions}
      />
      <Button
        id={ANALYTICS_ID.BUTTON_LOGIN}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {submitButtonText}
      </Button>
    </Box>
  );
};

