import React from 'react';
import { ConfirmationContentType, NextStepsType } from 'hooks/useConfirmationContent/types';
import { Box, Heading, Text } from '@myob/myob-widgets';
import style from './DefaultConfirmation.module.scss';

const NextSteps: React.FC<{ steps: NextStepsType }> = ({ steps: { items } }) => (
  <Box className={style.nextSteps}>
    <Heading level={4}>Next steps</Heading>
    <ol>
      {items.map((item, index) => (
        <Text fontWeight='inherit' key={index}>
          <li data-testid='next-step'>
            {item}
          </li>
        </Text>
      ))}
    </ol>
  </Box>
);
export const SubscribeContent: React.FC<ConfirmationContentType> = (description) => (
  <Box className={style.subscribeContent}>
    <Text fontWeight='inherit'>{description.header}</Text>
    {description.body && <Text fontWeight='inherit'>{description.body}</Text>}
    { description.nextSteps && <NextSteps steps={description.nextSteps} /> }
    <Text fontWeight='inherit'>
      {description.footer}
      {description.link && <a href={description.link} target='_blank' rel='noopener noreferrer'>
        {description.link}</a>}
    </Text>
  </Box>
);
