import React from 'react';
import { NinetailedProvider, useNinetailed } from '@ninetailed/experience.js-react';
import config from '../../Config';

export const handleProfileInit = (profileId: string | undefined): Promise<string | undefined> =>
  new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      resolve(profileId);
    }, 3000); // 3 seconds timeout

    // If segment analytics exists ... get one of the 2 kinds of ids
    // otherwise return the original ninetailed id
    analytics.ready(() => {
      const segmentId = analytics?.user?.()?.anonymousId?.();

      clearTimeout(timeoutId);
      resolve(segmentId ?? profileId ?? '');
    });
  });

interface Props {
  children: React.ReactNode;
}

const NinetailedConsumer: React.FC<Props> = ({ children }): JSX.Element => {
  const { page } = useNinetailed();
  page();

  return <>{children}</>;
};

const NinetailedWrapper: React.FC<Props> = ({ children }): JSX.Element => (
  <NinetailedProvider
    clientId={process.env.REACT_APP_NINETAILED_API_KEY}
    environment={config.NINETAILED_ENVIRONMENT}
    onInitProfileId={handleProfileInit}
  >
    <NinetailedConsumer>
      {children}
    </NinetailedConsumer>
  </NinetailedProvider>
);

export default NinetailedWrapper;
